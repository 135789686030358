import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClCardComponent } from './cl-card.component';



@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [ClCardComponent],
  exports: [ClCardComponent]
})
export class ClCardModule { }
