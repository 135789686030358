import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'lib-cl-card',
  templateUrl: './cl-card.component.html',
  styleUrls: ['./cl-card.component.scss'],
})
export class ClCardComponent implements OnInit {
  isShowMore = true;
  @Input() public datalist: any[];
  @Input() public cardClass: any;
  @Input() public hover: any = '';
  @Output() onDropdownClick = new EventEmitter();
  values: any;
  dataValues: any;
  clientName: any;
  requestId: any;
  status: any;
  priority: any;
  requesterName: any;
  description: any;
  indate: any;
  aging: any;
  responsibility: any;
  selectionType: any;
  arrayId: any;
  statusvalues: any[] = [
    { value: 'New', name: '#a3a0fb' },
    { value: 'UnAssigned', name: '#ffda83' },
    { value: 'Assigned', name: '#55d8fe' },
    { value: 'OverDue', name: '#ff8373' },
  ];
  priorityValues: any[] = [
    { value: 'High', name: '#fb788a' },
    { value: 'Medium', name: '#ffb822' },
    { value: 'Low', name: '#bbf7d0' },
  ];
  responsibilityValues: any[] = [
    { value: 'None', name: '#fb788a' },
    { value: 'Medium', name: '#ffb822' },
    { value: 'Low', name: '#bbf7d0' },
  ];
  constructor(private router: Router) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    for (const propertyName in changes) {
      if (propertyName === 'datalist') {
        const value = changes.datalist;
        const currentValue = value.currentValue;
        const previousValue = value.previousValue;
        this.dataValues = this.datalist;
      }
    }
  }

  data(id: any) {
    const detailsdata = this.dataValues.filter(
      (x: any) => x.id.toLowerCase() === id.toLowerCase()
    );
    this.clientName = detailsdata[0].clientName;
    this.requestId = detailsdata[0].id;
    this.status = detailsdata[0].status;
    this.priority = detailsdata[0].priority;
    this.requesterName = detailsdata[0].orgName;
    this.description = detailsdata[0].typeOfWork;
    this.indate = detailsdata[0].createdDate;
    this.aging = detailsdata[0].inDate;
    this.responsibility = detailsdata[0].responsibility;
  }

  valueSelected(value: any, requestId: any, selectionType: any) {
    this.onDropdownClick.emit({
      value: value.value,
      type: value.type,
      id: requestId,
      selectionType,
    });
  }

  showMore() {
    this.isShowMore = false;
  }

  showLess() {
    this.isShowMore = true;
  }
}
