import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClButtonComponent } from './cl-button.component';



@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [ClButtonComponent],
  exports: [ClButtonComponent]
})
export class ClButtonModule { }
