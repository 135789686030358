
import { Component, ElementRef, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';

@Component({
  selector: 'lib-cl-button',
  templateUrl: './cl-button.component.html',
  styleUrls: ['./cl-button.component.scss']
})
export class ClButtonComponent implements OnInit {
  @ViewChild('btnref') buttonref: ElementRef;
  @Input() label: string;
  @Input() type: string;
  @Input() className = 'primary';
  @Input() icon: string;
  @Input() btnSize: string;
  @Input() disabledButton: boolean;
  @Input() btnShadowHide = 'btnShadowHide';
  @Output() onClick = new EventEmitter<any>();
  constructor() { }

  ngOnInit(): void {
  }
  onClickButton(e: Event) {
    this.onClick.emit(e);
  }
}
